/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-21 18:02:32
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-21 18:03:32
 */
import RecordTable from './recordTable.vue'
export default RecordTable
