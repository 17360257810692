/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-21 18:02:59
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-22 10:27:47
 */
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex'
import { getNormalWeek, moneyFormat } from '@/plugins/utils'
import EnumMap from '@/plugins/enumMap'
import moment from 'moment'
import eventBus from '@/service/eventBus'

const { mapActions, mapGetters } = createNamespacedHelpers('App/Finance')

export default {
  name: 'RecordTable',
  data () {
    return {
    }
  },
  computed: {

    ...mapRootGetters([
      'enum_map'
    ]),

    ...mapGetters([
      'queryForm'
    ]),

    // 支付状态选项
    statusOptions () {
      return [{ id: 0, name: '全部' }].concat(this.enum_map.pay_status || [])
    },

    // 交易类型选项
    dealTypeOptions () {
      return [{ id: 0, name: '全部' }].concat(this.enum_map.deal_type || [])
    },

    // 列表请求参数
    tableQuery () {
      return ['start_date', 'end_date', 'pay_status'].reduce((query, key) => {
        query[key] = this.queryForm[key]
        return query
      }, {})
    }
  },
  async mounted () {
    const [startDate, endDate] = getNormalWeek(true)
    await this.updateQueryForm({
      start_date: startDate,
      end_date: endDate,
      _date: [new Date(startDate), new Date(endDate)]
    })
    // 添加页面刷新监听
    eventBus.$on('app-finance-refresh', () => {
      console.log('table refresh')
      this.getFinanceInfo()
      this.refreshTableList()
    })
  },
  methods: {

    ...mapActions([
      'updateQueryForm',
      'getFinanceInfo'
    ]),

    /**
     * 处理交易类型显示
     */
    dealTypeName (id) {
      return (new EnumMap('deal_type')).mapping(id)
    },

    /**
     * 支付状态显示
     */
    payStatusName (id) {
      return (new EnumMap('pay_status')).mapping(id)
    },

    /**
     * 支付状态显示
     */
    payStatusTagType (id) {
      switch (id) {
        case 1:
          return 'warning'
        case 2:
          return ''
        case 3:
          return 'danger'
        default:
          return ''
      }
    },

    /**
     * 金钱格式化
     */
    moneyFormat,

    /**
     * 更新列表
     */
    async refreshTableList () {
      // const tabelEle = this.$refs['w-table']
      this.$nextTick(async () => {
        const tabelEle = this.$refs['w-table']
        if (tabelEle) {
          await tabelEle.resetList()
        }
      })
    },

    /**
     * 日期选择
     */
    async dateChange (dates) {
      console.log(dates)
      if (Array.isArray(dates)) {
        let [startDate, endDate] = dates
        startDate = moment(startDate).format('YYYY-MM-DD')
        endDate = moment(endDate).format('YYYY-MM-DD')
        await this.updateQueryForm({
          start_date: startDate,
          end_date: endDate
        })
        this.refreshTableList()
      }
    }
  }
}
